import actions from '@/store/modules/history/actions'
import mutations from '@/store/modules/history/mutations'
import getters from '@/store/modules/history/getters'

export const state: IStateHistory = {
  humanMotionHistory: []
}

export const history = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateHistory {
  humanMotionHistory: IMotionEventData[]
}

export type IMotionEventData = {
  startTsMs: number
  startTime: string
  stopTsMs: number
  duration: string
}

export type IMotionEventRange = {
  from: number
  to: number
  color: string
}

export type IRecentHistoryEventGraph = {
  x: number
  y: number
  z: number
}
