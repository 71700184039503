import moment from 'moment'

import { IStateHistory, IMotionEventData } from '@/store/modules/history'
import { APIEventInRecentHistory } from '@/type/things/motion/recentHistory'

export default {
  setHumanMotionHistory(state: IStateHistory, history: APIEventInRecentHistory[]) {
    const filteredEvents: APIEventInRecentHistory[] = []
    history.forEach((event, index) => {
      if (index > 0 && event.humanMotionDetected !== history[index - 1].humanMotionDetected) {
        filteredEvents.push(event)
      }
    })

    const result: IMotionEventData[] = []
    filteredEvents.forEach((event, index) => {
      if (event.humanMotionDetected === false && index > 0) {
        let secs = Math.round((event.tsMs - filteredEvents[index - 1].tsMs) / 1000)
        if (secs === 0) secs = 1

        const toPush = {
          startTsMs: filteredEvents[index - 1].tsMs,
          startTime: moment(filteredEvents[index - 1].tsMs).format('HH:mm:ss'),
          stopTsMs: event.tsMs,
          duration: secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`
        }

        result.push(toPush)
      }
    })

    state.humanMotionHistory = result.reverse()
  },

  resetHumanMotionHistory(state: IStateHistory) {
    state.humanMotionHistory = []
  }
}
