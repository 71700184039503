import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'
import { AxiosResponse } from 'axios'
import { APIThing } from '@/type/things/thing'

export default {
  async getCompositeStatus({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const responseOne: AxiosResponse<APIThing> = await fetchApi.get(`/thing/${serial}-c0`)
      const responseTwo: AxiosResponse<APIThing> = await fetchApi.get(`/thing/${serial}-c1`)
      const responseThree: AxiosResponse<APIThing> = await fetchApi.get(`/thing/${serial}-c2`)

      Promise.all([responseOne.data, responseTwo.data, responseThree.data]).then(compositeValues => {
        commit('composite/setComposite', compositeValues, { root: true })
      })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async putCompositeAlias({ commit }: any, { serial, alias }: any) {
    try {
      const response: AxiosResponse<any> = await fetchApi.put(`/thing/${serial}/alias`, {
        alias
      })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async deleteCompositeAlias({ commit }: any, { serial }: any) {
    try {
      const response: AxiosResponse<any> = await fetchApi.delete(`/thing/${serial}/alias`)
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async setCompositeSensitivity({ commit }: any, { serial, value }: any) {
    try {
      const response: AxiosResponse = await fetchApi.patch(`/thing/${serial}/state/desired`, {
        motionAlgoSensitivity: value
      })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async setCompositeSensitivityMaster({ commit, rootState }: any, { value }: any) {
    const { serial } = rootState.thing

    try {
      const responseOne: AxiosResponse = await fetchApi.patch(`/thing/${serial}-c0/state/desired`, {
        motionAlgoSensitivity: value
      })
      const responseTwo: AxiosResponse = await fetchApi.patch(`/thing/${serial}-c1/state/desired`, {
        motionAlgoSensitivity: value
      })
      const responseThree: AxiosResponse = await fetchApi.patch(`/thing/${serial}-c2/state/desired`, {
        motionAlgoSensitivity: value
      })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
