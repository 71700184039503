import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// route level are code-splitting with webpackChunkName
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const routes = [
  {
    path: '/',
    name: 'live',
    component: () => import(/* webpackChunkName: "live" */ '@/views/Live.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "history" */ '@/views/History.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import(/* webpackChunkName: "setup" */ '@/views/Setup.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/settings/:serial',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    // will match everything means 404 error page
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { transitionName: 'slide' }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
