




import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Chart } from 'highcharts-vue'
import moment from 'moment'

import { IStateActivity, MotionEnum } from '@/store/modules/activity'
import { IStateTheme } from '@/store/modules/theme'
import { IStateThing } from '@/store/modules/thing'
import chartOptions from '@/utils/charts/liveChart'

@Component({
  components: {
    Chart
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateActivity>('activity', {
      motionStatus: (state: IStateActivity) => state.motionStatus
    }),
    ...mapState<IStateTheme>('theme', {
      mobile: (state: IStateTheme) => state.mobile
    })
  }
})
export default class ChartComponent extends Vue {
  private chartOptions: any = chartOptions

  @Watch('serial')
  @Watch('mobile')
  cleanData() {
    const { chartLiveReference } = this.$refs
    if (!chartLiveReference) return

    const chartSerie = (chartLiveReference as any).chart.series[0]
    chartSerie.setData([])
  }

  @Watch('motionStatus')
  @Watch('mobile')
  updateData() {
    const motionStatus = this.$store.state.activity.motionStatus
    const { chartLiveReference } = this.$refs
    if (!chartLiveReference || !motionStatus) return

    const { activityLevel, humanMotionDetected, timestamp } = motionStatus
    if (!activityLevel || !timestamp) return

    const chartSerie = (chartLiveReference as any).chart.series[0]
    const maxLengthVisible = this.$store.state.theme.mobile ? 10 : 20
    const shift = chartSerie.data.length > maxLengthVisible

    const timeZoneEffect = new Date(timestamp).getTimezoneOffset() * 60
    const x = new Date(timestamp).getTime() - timeZoneEffect * 1000
    const y = activityLevel
    const z = humanMotionDetected === true ? MotionEnum.Human : MotionEnum.None

    chartSerie.addPoint({ x, y, z }, true, shift)
  }

  @Watch('mobile')
  updateHeight() {
    const chartElement: any = this.$refs.chartLiveReference
    if (!chartElement || !chartElement.chart) return

    const cardParent = document.querySelector('.card')

    let chartHeight = 400
    if (cardParent && cardParent.clientHeight > 500) chartHeight = Math.round(cardParent.clientHeight / 2)

    chartElement.chart.setSize(null, chartHeight)
  }

  mounted() {
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
  }
}
