












import Vue from 'vue'
import { mapState } from 'vuex'

import { IStateOrg } from '@/store/modules/org'
import { IStateThing } from '@/store/modules/thing'
import { IStateThings } from '@/store/modules/things'

export default Vue.extend({
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateThing>('things', {
      things: (state: IStateThings) => state.things
    }),
    ...mapState<IStateOrg>('org', {
      org: (state: IStateOrg) => state.org
    })
  },
  methods: {
    setSerial(serial: string | null) {
      this.$store.commit('thing/setSerial', serial)
    }
  },
  watch: {
    org: function() {
      this.$store.commit('things/resetThings')
      this.$store.dispatch('things/getThings')
    }
  },
  mounted() {
    const haveThings = this.$store.state.things.things.length
    if (haveThings) return

    this.$store.dispatch('things/getThings')
  }
})
