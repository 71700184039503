var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { class: { "drawer--left--open": _vm.drawer === _vm.drawers.Nav } },
    [
      _c(
        "button",
        { staticClass: "drawer__close", on: { click: _vm.closeDrawers } },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "times"] } })],
        1
      ),
      _c(
        "nav",
        { staticClass: "nav" },
        [
          _c(
            "router-link",
            {
              staticClass: "nav__link",
              attrs: { to: "/" },
              nativeOn: {
                click: function($event) {
                  return _vm.closeDrawers($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Drawer.Nav.Live")) + " ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "nav__link",
              attrs: { to: "/history" },
              nativeOn: {
                click: function($event) {
                  return _vm.closeDrawers($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Drawer.Nav.History")) + " ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "nav__link",
              attrs: { to: "/setup" },
              nativeOn: {
                click: function($event) {
                  return _vm.closeDrawers($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Drawer.Nav.Setup")) + " ")]
          ),
          _c("small", { staticClass: "nav__version" }, [
            _vm._v("Aerial © - " + _vm._s(_vm.appVersion))
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }