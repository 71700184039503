var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compositesConnected === null
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon info",
              attrs: { icon: ["fas", "info-circle"] }
            })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("Card.Live.Loading")) + " ")
          ])
        ])
      ])
    : !_vm.compositesConnected
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon info",
              attrs: { icon: ["fas", "info-circle"] }
            })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("Card.Live.NotConnected")) + " ")
          ])
        ])
      ])
    : _vm.motionStatus
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _vm.current.humanMotion === false
              ? _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: ["fas", "male"] }
                })
              : _c("font-awesome-icon", {
                  staticClass: "icon human",
                  attrs: { icon: ["fas", "walking"] }
                })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _vm.current.humanMotion === true
            ? _c("p", { staticClass: "title red" }, [
                _vm._v(" " + _vm._s(_vm.$t("Card.Live.Human")) + " ")
              ])
            : _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("Card.Live.NoMotion")))
              ]),
          _c("p", [_vm._v(_vm._s(_vm.date) + " - " + _vm._s(_vm.duration))])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }