









































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import moment from 'moment'

import { IStateActivity, MotionEnum } from '@/store/modules/activity'
import { IStateComposite } from '@/store/modules/composite'
import { IStateThing } from '@/store/modules/thing'
import { APIMotionStatus } from '@/type/things/motion/status'

export default Vue.extend({
  data() {
    return {
      date: moment(Date.now()).format('HH:mm:ss'),
      duration: '0 sec',
      current: {
        humanMotion: false,
        tsMs: moment().valueOf()
      },
      MotionEnum
    }
  },
  computed: {
    ...mapState<IStateActivity>('activity', {
      motionStatus: (state: IStateActivity) => state.motionStatus
    }),
    ...mapGetters('composite', ['compositesConnected'])
  },
  watch: {
    motionStatus: function(motionStatus: APIMotionStatus) {
      if (!motionStatus || !motionStatus.timestamp) return

      const previousMotion = this.current
      const currentTsMs = Date.parse(motionStatus.timestamp)
      let currentHumanMotion = false

      if (motionStatus.humanMotionDetected) currentHumanMotion = true

      if (currentHumanMotion === previousMotion.humanMotion) {
        const fromDate = previousMotion.tsMs
        const toDate = currentTsMs

        let secs = Math.round((toDate - fromDate) / 1000)
        if (secs === 0) secs = 1
        this.duration = secs <= 59 ? `${secs} sec` : `${Math.floor(secs / 60)} mn ${secs % 60} sec`
      } else {
        this.duration = '0 sec'
        this.date = moment(currentTsMs).format('HH:mm:ss')
        this.current = {
          humanMotion: currentHumanMotion,
          tsMs: currentTsMs
        }
      }
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.date = moment(Date.now()).format('HH:mm:ss')
        this.duration = '0 sec'
        this.current = {
          humanMotion: false,
          tsMs: moment().valueOf()
        }
      }
    })
  }
})
