var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "logs" },
      [
        _vm.getMotionLogs.length === 0
          ? _c("p", [_vm._v(_vm._s(_vm.$t("Card.Live.NoMotion")))])
          : _vm._l(_vm.getMotionLogs, function(motionLog, index) {
              return _c("div", { key: index, staticClass: "log" }, [
                _c(
                  "div",
                  { staticClass: "log__picto" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "log__motion-icon",
                      attrs: { icon: ["fas", "walking"] }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "log__text" }, [
                  _c("p", { staticClass: "log__title" }, [
                    _vm._v(_vm._s(_vm.$t("Card.Live.Human")))
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "log__time" }, [
                      _vm._v(" " + _vm._s(motionLog.startTsMs) + " ")
                    ]),
                    _vm._v(" - " + _vm._s(motionLog.duration) + " ")
                  ])
                ])
              ])
            })
      ],
      2
    ),
    _vm.getMotionLogs.length > 3
      ? _c(
          "div",
          { staticClass: "scroll" },
          [_c("font-awesome-icon", { attrs: { icon: ["fas", "caret-down"] } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }