









import Vue from 'vue'

import Date from '@/components/Card/History/Date.vue'
import Logs from '@/components/Card/History/Logs.vue'
import HumanEventChart from '@/components/Card/History/Index.vue'
import SelectThing from '@/components/Select/Thing.vue'

export default Vue.extend({
  components: {
    Date,
    Logs,
    HumanEventChart,
    SelectThing
  }
})
