var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("Header", { attrs: { compositeSerial: _vm.$route.params.serial } }),
        _c("Alias"),
        _vm.compositeConnected &&
        _vm.clientsLoad &&
        _vm.compositeStatus.agentWifiOpMode === _vm.AgentModeEnum.Ap
          ? _c(
              "div",
              { staticClass: "select-container" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("Card.Client.SelectClient")))
                ]),
                _c("SelectClient", {
                  attrs: { compositeSerial: _vm.$route.params.serial }
                })
              ],
              1
            )
          : _vm._e(),
        _c("Sensitivity")
      ],
      1
    ),
    _c(
      "button",
      {
        staticClass: "button button--red",
        on: {
          click: function($event) {
            return _vm.$router.go(-1)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("Card.Alias.Back")) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }