import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import { IUser } from '@/store/modules/user'

export function initSentry() {
  Sentry.init({
    dsn: 'https://f90fbdc6d6e14ec7a326487b79e99183@o372803.ingest.sentry.io/5192273',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: process.env.NODE_ENV,
    release: process.env.PACKAGE_VERSION
  })
}

export function setSentryUser(user: IUser) {
  Sentry.configureScope(scope => {
    scope.setUser(user)
  })
}

export function sentryFetchError(error: any) {
  if (!error || process.env.NODE_ENV === 'test') return

  const { code, message } = error.data
  const { url, method } = error.config

  Sentry.addBreadcrumb({
    category: 'fetch',
    data: {
      code,
      message,
      url,
      method
    },
    level: Sentry.Severity.Error,
    type: 'http'
  })

  const fetchError = new Error(`Fetch error - ${code} on ${method}: ${url}`)
  Sentry.captureException(fetchError)
}
