var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabs" }, [
    _c(
      "button",
      {
        class: { active: _vm.selected === _vm.TabsEnum.MotionChart },
        on: {
          click: function($event) {
            return _vm.toggleTab(_vm.TabsEnum.MotionChart)
          }
        }
      },
      [
        _c("font-awesome-icon", {
          staticClass: "icon",
          attrs: { icon: ["fas", "chart-area"] }
        }),
        _vm._v(" " + _vm._s(_vm.$t("Card.Live.Chart")) + " ")
      ],
      1
    ),
    _c(
      "button",
      {
        class: { active: _vm.selected === _vm.TabsEnum.MotionLogs },
        on: {
          click: function($event) {
            return _vm.toggleTab(_vm.TabsEnum.MotionLogs)
          }
        }
      },
      [
        _c("font-awesome-icon", {
          staticClass: "icon",
          attrs: { icon: ["fas", "list-alt"] }
        }),
        _vm._v(" " + _vm._s(_vm.$t("Card.Live.Logs")) + " ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }