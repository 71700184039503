import { IStateClient } from '@/store/modules/client'

export default {
  compositeOnlineClients: (state: IStateClient, getters: any, rootState: any) => (serial: string) => {
    const compositeThings = rootState.composite.compositeThings
    const compositeIndex = compositeThings.findIndex((composite: any) => composite.serial === serial)
    const compositeReported = compositeThings[compositeIndex].clientReported
    const clientIndex = state.clients.findIndex(composite => composite.serial === serial)
    if (!state.clients[clientIndex]) return

    const clientsOnline = state.clients[clientIndex].connectedClients

    return clientsOnline.includes(compositeReported)
  },

  compositeClientOptions: (state: IStateClient) => (serial: string) => {
    const index = state.clients.findIndex(composite => composite.serial === serial)
    return state.clients[index].clientOptions
  }
}
