export type IThingStatusInfo = {
  message: string
  notif: string
  type: ThingStatusTypeEnum
}

export enum ThingStatusTypeEnum {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Valid = 'valid'
}

export enum OpStatusCodeEnum {
  // UNSET
  UNSET_CLIENT = 0,
  // VALID
  VALID_RUNNING_NORMAL = 1,
  // INFO
  INFO_INITIALIZING = 2,
  INFO_FILLING_BUFFERS = 3,
  INFO_CALIB_COMPLETE = 4,
  INFO_SELECTING_WIFI_CLIENT = 5,
  // WARNING
  WARNING_DEGRADED = 128,
  // ERROR
  ERROR_UNHANDLED = 160,
  ERROR_LOW_PACKET_RATE = 162,
  ERROR_NO_AP_IP_FOUND = 163,
  ERROR_CANNOT_OPEN_CSI_DEV = 164,
  ERROR_NOT_RECEIVING_CSI = 165,
  ERROR_READING_CSI = 166,
  ERROR_DROPPING_PACKETS = 167,
  ERROR_NETWORK_LATENCY = 168
}

export const loadingStatusInfo: IThingStatusInfo = {
  message: 'Default.Loading',
  notif: 'Default.Loading',
  type: ThingStatusTypeEnum.Info
}

export const offlineStatusInfo: IThingStatusInfo = {
  message: 'Notif.Thing.Info.Offline',
  notif: 'Notif.Thing.Info.Offline',
  type: ThingStatusTypeEnum.Error
}

export const getOpStatusInfo = (opStatusCode: number): IThingStatusInfo => {
  switch (opStatusCode) {
    // UNSET
    case OpStatusCodeEnum.UNSET_CLIENT:
      return {
        message: 'Notif.Thing.Message.UnsetClient',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    // VALID
    case OpStatusCodeEnum.VALID_RUNNING_NORMAL:
      return {
        message: 'Notif.Thing.Message.ValidRunningNormal',
        notif: 'Notif.Thing.Info.Valid',
        type: ThingStatusTypeEnum.Valid
      }

    // INFO
    case OpStatusCodeEnum.INFO_INITIALIZING:
      return {
        message: 'Notif.Thing.Message.InfoInitializing',
        notif: 'Notif.Thing.Info.Init',
        type: ThingStatusTypeEnum.Info
      }

    case OpStatusCodeEnum.INFO_FILLING_BUFFERS:
      return {
        message: 'Notif.Thing.Message.InfoFillingBuffers',
        notif: 'Notif.Thing.Info.Init',
        type: ThingStatusTypeEnum.Info
      }

    case OpStatusCodeEnum.INFO_CALIB_COMPLETE:
      return {
        message: 'Notif.Thing.Message.InfoCalibComplete',
        notif: 'Notif.Thing.Info.Init',
        type: ThingStatusTypeEnum.Info
      }

    case OpStatusCodeEnum.INFO_SELECTING_WIFI_CLIENT:
      return {
        message: 'Notif.Thing.Message.InfoSelectingWifiClient',
        notif: 'Notif.Thing.Info.Init',
        type: ThingStatusTypeEnum.Info
      }

    // WARNING
    case OpStatusCodeEnum.WARNING_DEGRADED:
      return {
        message: 'Notif.Thing.Message.WarningDegraded',
        notif: 'Notif.Thing.Message.Degraded',
        type: ThingStatusTypeEnum.Warning
      }

    // ERROR
    case OpStatusCodeEnum.ERROR_UNHANDLED:
      return {
        message: 'Notif.Thing.Message.ErrorUnhandled',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_LOW_PACKET_RATE:
      return {
        message: 'Notif.Thing.Message.ErrorLowPacketRate',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_NO_AP_IP_FOUND:
      return {
        message: 'Notif.Thing.Message.ErrorNoApIpFound',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_CANNOT_OPEN_CSI_DEV:
      return {
        message: 'Notif.Thing.Message.ErrorCannotOpenCsiDev',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_NOT_RECEIVING_CSI:
      return {
        message: 'Notif.Thing.Message.ErrorNotReceivingCsi',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_READING_CSI:
      return {
        message: 'Notif.Thing.Message.ErrorReadingCsi',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_DROPPING_PACKETS:
      return {
        message: 'Notif.Thing.Message.ErrorDroppingPackets',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    case OpStatusCodeEnum.ERROR_NETWORK_LATENCY:
      return {
        message: 'Notif.Thing.Message.ErrorNetworkLatency',
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }

    default:
      return loadingStatusInfo
  }
}
