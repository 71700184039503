import {
  IStateDiagnostic,
  ICompositeDiagnosticResponse,
  ICompositeDiagnostic
} from '@/store/modules/diagnostic'

export default {
  setCompositeDiagnostic(state: IStateDiagnostic, { serial, response }: ICompositeDiagnosticResponse) {
    if (response === null) return
    const { strength } = response.signal
    const compositeDiagnostic = {
      serial,
      strength
    } as ICompositeDiagnostic

    state.strength = [...state.strength, compositeDiagnostic]
  },

  resetCompositeDiagnostic(state: IStateDiagnostic) {
    state.strength = []
  }
}
