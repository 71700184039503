import { ThingStatusTypeEnum } from '@/utils/thingStatus'
import { IStateComposite, ICompositeThing } from '@/store/modules/composite'

export default {
  compositesStatus: (state: IStateComposite) => {
    const { compositeThings } = state

    if (compositeThings.every((thing: ICompositeThing) => !thing.connected)) {
      return {
        notif: 'Notif.Thing.Info.Offline',
        type: ThingStatusTypeEnum.Info
      }
    }

    const connectedThings = compositeThings.filter((thing: ICompositeThing) => thing.connected)

    if (connectedThings.some(({ opStatus }: ICompositeThing) => opStatus.type === ThingStatusTypeEnum.Info)) {
      return {
        notif: 'Notif.Thing.Info.Init',
        type: ThingStatusTypeEnum.Info
      }
    }

    if (
      connectedThings.some(({ opStatus }: ICompositeThing) => opStatus.type === ThingStatusTypeEnum.Error)
    ) {
      return {
        notif: 'Notif.Thing.Info.Error',
        type: ThingStatusTypeEnum.Error
      }
    }

    if (
      connectedThings.some(({ opStatus }: ICompositeThing) => opStatus.type === ThingStatusTypeEnum.Warning)
    ) {
      return {
        notif: 'Notif.Thing.Info.Degraded',
        type: ThingStatusTypeEnum.Warning
      }
    }

    return {
      notif: 'Notif.Thing.Info.Valid',
      type: ThingStatusTypeEnum.Valid
    }
  },

  compositeStatus: (state: IStateComposite) => (serial: string) => {
    return state.compositeThings.find(composite => composite.serial === serial)
  },

  compositeClientsMatch: (state: IStateComposite) => (serial: string) => {
    const index = state.compositeThings.findIndex(composite => composite.serial === serial)
    const { clientReported, clientDesired } = state.compositeThings[index]
    return clientReported === clientDesired
  },

  compositeClientReported: (state: IStateComposite) => (serial: string) => {
    const index = state.compositeThings.findIndex(composite => composite.serial === serial)
    return state.compositeThings[index].clientReported
  },

  compositeAlias: (state: IStateComposite) => (serial: string) => {
    const index = state.compositeThings.findIndex(composite => composite.serial === serial)
    return state.compositeThings[index].alias.replace(/_/g, ' ')
  },

  compositeOpStatus: (state: IStateComposite) => (serial: string) => {
    const index = state.compositeThings.findIndex(composite => composite.serial === serial)
    return state.compositeThings[index].opStatus.type
  },

  compositeSensitivity: (state: IStateComposite) => (serial: string) => {
    const index = state.compositeThings.findIndex(composite => composite.serial === serial)
    return state.compositeThings[index].sensitivityReported
  },

  compositeSensitivityMatch: (state: IStateComposite) => (serial: string) => {
    const { compositeThings } = state
    const connectedThings = compositeThings.filter((thing: ICompositeThing) => thing.connected)

    const index = connectedThings.findIndex(composite => composite.serial === serial)
    const { sensitivityReported, sensitivityDesired } = connectedThings[index]
    return sensitivityReported === sensitivityDesired
  },

  compositeSensitivitiesMatch: (state: IStateComposite): Boolean => {
    const { compositeThings } = state
    const connectedThings = compositeThings.filter((thing: ICompositeThing) => thing.connected)

    return connectedThings.every(
      (thing: ICompositeThing) => thing.sensitivityReported === thing.sensitivityDesired
    )
  },

  compositeSensitivityMaster: (state: IStateComposite): null | number => {
    const { compositeThings } = state
    const connectedThings = compositeThings.filter((thing: ICompositeThing) => thing.connected)

    const sensitivities = connectedThings.map((thing: ICompositeThing) => thing.sensitivityReported)
    const masterSensitivity = sensitivities[0]

    return sensitivities.every((sensitivity: number) => sensitivity === masterSensitivity)
      ? masterSensitivity
      : null
  },

  compositeConnected: (state: IStateComposite) => (serial: string) => {
    const index = state.compositeThings.findIndex(composite => composite.serial === serial)
    return state.compositeThings[index].connected
  },

  compositesConnected: (state: IStateComposite): boolean | null => {
    const { compositeThings } = state

    let connected = null
    if (!compositeThings.length) return connected

    const connectedThing = compositeThings.filter((thing: ICompositeThing) => thing.connected)
    connected = connectedThing.length > 0

    return connected
  }
}
