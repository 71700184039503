













import Vue from 'vue'

export enum TabsEnum {
  MotionChart = 'MotionChart',
  MotionLogs = 'MotionLogs'
}

export default Vue.extend({
  data() {
    return {
      TabsEnum,
      selected: TabsEnum.MotionChart
    }
  },
  methods: {
    toggleTab(tab: TabsEnum) {
      this.selected = tab
      this.$emit('toggle-tab', tab)
    }
  }
})
