import moment from 'moment'

import { IStateActivity, IMotionLog } from '@/store/modules/activity'
import { APIMotionStatus } from '@/type/things/motion/status'

export default {
  setMotionStatus(state: IStateActivity, motionsStatus: APIMotionStatus) {
    state.motionStatus = motionsStatus
  },

  resetMotionStatus(state: IStateActivity) {
    state.motionStatus = null
  },

  setMotionLogs(state: IStateActivity, motionsStatus: APIMotionStatus) {
    if (
      !state.motionStatus ||
      !motionsStatus ||
      !motionsStatus.timestamp ||
      motionsStatus.humanMotionDetected === null
    )
      return

    if (
      state.motionLogs.length === 0 ||
      state.motionLogs[state.motionLogs.length - 1].humanMotionDetected !== motionsStatus.humanMotionDetected
    ) {
      state.motionLogs = [
        ...state.motionLogs,
        {
          humanMotionDetected: motionsStatus.humanMotionDetected,
          timestamp: moment(motionsStatus.timestamp).valueOf(),
          date: moment
            .utc(motionsStatus.timestamp, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format('HH:mm:ss')
        } as IMotionLog
      ]
    }
  },

  resetMotionLogs(state: IStateActivity) {
    state.motionLogs = []
  }
}
