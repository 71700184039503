import { AxiosResponse } from 'axios'

import { APIMotionStatus } from '@/type/things/motion/status'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getMotionStatus({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIMotionStatus> = await fetchApi.get(
        `/thing/${serial}/motion/compositeStatus`
      )
      commit('activity/setMotionStatus', response.data, { root: true })
      commit('activity/setMotionLogs', response.data, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
