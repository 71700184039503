import actions from '@/store/modules/activity/actions'
import getters from '@/store/modules/activity/getters'
import mutations from '@/store/modules/activity/mutations'
import { APIMotionStatus } from '@/type/things/motion/status'

export enum MotionEnum {
  Human = 'Human motion',
  None = 'No motion'
}

export const state: IStateActivity = {
  motionStatus: null,
  motionLogs: []
}

export const activity = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateActivity {
  motionStatus: APIMotionStatus | null
  motionLogs: IMotionLog[]
}

export interface IMotionLog {
  humanMotionDetected: boolean
  timestamp: number
  date: string
}

export interface IMotionLogEvent {
  duration: string
  startTsMs: string
}
