var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compositeConnected
    ? _c("div", [
        _vm.compositeDiagnostic === _vm.DiagnosticEnum.TooClose
          ? _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("Card.Diagnostic.TooClose")) + " ")
            ])
          : _vm.compositeDiagnostic === _vm.DiagnosticEnum.TooFar
          ? _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("Card.Diagnostic.TooFar")) + " ")
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }