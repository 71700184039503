











import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DiagnosticEnum } from '@/store/modules/diagnostic'

@Component
export default class Diagnostic extends Vue {
  DiagnosticEnum: typeof DiagnosticEnum = DiagnosticEnum
  @Prop({ required: true }) readonly compositeSerial!: string

  get compositeConnected() {
    return this.$store.getters['composite/compositeConnected'](this.compositeSerial)
  }

  get compositeDiagnostic() {
    return this.$store.getters['diagnostic/compositeDiagnostic'](this.compositeSerial)
  }

  @Watch('compositeConnected', { immediate: true })
  refreshSignal() {
    const serial = this.compositeSerial
    if (!this.compositeConnected) return

    this.$store.dispatch('diagnostic/getCompositeDiagnostic', { serial })
  }
}
