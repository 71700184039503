import mutations from '@/store/modules/things/mutations'
import actions from '@/store/modules/things/actions'

export const state: IStateThings = {
  things: []
}

export const things = {
  namespaced: true,
  state,
  mutations,
  actions
}

// Types
export interface IStateThings {
  things: string[] | []
}
