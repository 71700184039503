import { IStateActivity, IMotionLog } from '@/store/modules/activity'

export default {
  getMotionLogs: ({ motionLogs }: IStateActivity) => {
    const result = motionLogs
      .map((log: IMotionLog, index: number) => {
        // skip index 0 to insure we have true human event in logs
        if (log.humanMotionDetected === false && index > 0) {
          const startTsMs = motionLogs[index - 1].date

          let secs = Math.round((log.timestamp - motionLogs[index - 1].timestamp) / 1000)
          if (secs === 0) secs = 1
          const duration = secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`

          return { startTsMs, duration }
        }
      })
      .filter(event => event !== undefined)
      .reverse()

    return result
  }
}
